import React from 'react'

function Project (){
    return (
        <div>
            <p className='design'></p>
            <h1 className ='projects'>Coming Soon!</h1>
        </div>
    )
}


export default Project